import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlasses, faCogs, faTags } from '@fortawesome/free-solid-svg-icons';
import styles from './SearchWizard.module.css';
import WhiteMainBackground from '../../shared_components/WhiteMainBackground/WhiteMainBackground';

function SearchWizard() {
	return (
		<WhiteMainBackground>
			<div className={styles.container}>
				<div className={styles.card}>
					<div className={styles.icon}>
						<FontAwesomeIcon icon={faGlasses} />
					</div>
					<div className={styles.text}>Knowledge Center</div>
				</div>
				<div className={styles.card}>
					<div className={styles.icon}>
						<FontAwesomeIcon icon={faCogs} />
					</div>
					<div className={styles.text}>Design Guidelines</div>
				</div>
				<div className={styles.card}>
					<div className={styles.icon}>
						<FontAwesomeIcon icon={faTags} />
					</div>
					<div className={styles.text}>Tags</div>
				</div>
			</div>
		</WhiteMainBackground>
	);
}

export default SearchWizard;
