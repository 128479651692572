// import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import PluginHome from './PluginHome/PluginHome';
import AudioHome from './AudioHome/AudioHome';
import NotFound from './NotFound/NotFound';
import MainStyle from './Main.module.css';
import GeneratedAssetDetail from './GeneratedAssetDetail/GeneratedAssetDetail';
import AssetPreview from './AssetPreview/AssetPreview';
import NewAsset from './NewAsset/NewAsset';
import DocumentationHome from './Documentation/DocumentationHome';
import PluginGlossary from './PluginGlossary/PluginGlossary';
import Users from './Users/Users';
import UserData from '../services/UserData';
import { useContext } from 'react';
import AiImageGeneratorHome from './ImageGenerator/AiImageGeneratorHome';
import AiImageGeneratorView from './ImageGenerator/AiImageGeneratorView/AiImageGeneratorView';
import AiImageView from './ImageGenerator/AiImageView/AiImageView';
import AIImageService from '../services/AIImageService';
import Permissions from '../services/Permissions';
import AIEssayService from '../services/AIEssayService';
import AIEssay from './AIEssay/AIEssay';
import AIEssayMark from './AIEssay/AIEssayMark/AIEssayMark';
import SearchWizard from './SearchWizard/SearchWizard';
import Settings from './Settings/Settings';

// const Brands = React.lazy(() => import('./Brands/brands'));
// const Division = React.lazy(() => import('./Division/division'));
// const Promotions = React.lazy(() => import('./Promotions/promotions'));
// const About = React.lazy(() => import('./About/about'));

// <Route path='/about' exact render={() => <Suspense fallback={<div>Loading...</div>}><About /></Suspense>}  />
// <Route path='/brands' exact render={() => <Suspense fallback={<div>Loading...</div>}><Brands /></Suspense>} />
// <Route path='/services' exact render={() => <Suspense fallback={<div>Loading...</div>}><Division /></Suspense>} />

// const DocumentationHome = lazy(() => import('./Documentation/DocumentationHome.js'));

const Main = () => {
	const userData = useContext(UserData);
	return (
		<div className={MainStyle.main}>
			<Routes>
				<Route path='/' Component={PluginHome} />
				<Route path='/asset/:assetType' Component={NewAsset} />
				<Route path='/asset/:assetType/:assetKey' Component={NewAsset} />
				<Route path='/plugins' Component={PluginHome} />
				<Route
					path='/plugins/asset/:assetType/:assetKey'
					Component={GeneratedAssetDetail}
				/>
				<Route
					path='/plugins/view/:assetType/:assetKey/:assetName'
					Component={AssetPreview}
				/>
				<Route path='/plugins/:page' Component={PluginHome} />
				<Route path='/audio' Component={AudioHome} />
				<Route
					path='/audio/asset/:assetType/:assetKey'
					Component={GeneratedAssetDetail}
				/>

				<Route path='/audio/:page' Component={AudioHome} />
				<Route path='/glossary' Component={PluginGlossary} />
				<Route path='/documentation' Component={DocumentationHome} />
				<Route path='/users/*' Component={Users} />

				{Permissions.displayIfAnyOfTheRolesAreAllowed(
					userData,
					AIImageService.allowedRoles,
					<>
						<Route path='/gallery' element={<AiImageGeneratorHome />} />
						<Route path='/image/generate' element={<AiImageGeneratorView />} />
						<Route path='/gallery/image/:imageId' element={<AiImageView />} />
					</>
				)}

				{/* <Route path='/documentation' render={() => <Suspense fallback={<Spinner />}><DocumentationHome /></Suspense>} /> */}
				{Permissions.displayIfAnyOfTheRolesAreAllowed(
					userData,
					AIEssayService.allowedRoles,
					<Route path='/ai-essay' Component={AIEssay} />
				)}
				{Permissions.displayIfAnyOfTheRolesAreAllowed(
					userData,
					AIEssayService.allowedRoles,
					<Route path='/ai-essay/:page' Component={AIEssay} />
				)}
				{Permissions.displayIfAnyOfTheRolesAreAllowed(
					userData,
					AIEssayService.allowedRoles,
					<Route path='/ai-essay/mark/:guid' Component={AIEssayMark} />
				)}

				{Permissions.displayIfAnyOfTheRolesAreAllowed(
					userData,
					AIEssayService.allowedRoles,
					<Route path='/searh-wizard' Component={SearchWizard} />
				)}
				<Route path='/settings/*' Component={Settings} />
				<Route path='*' Component={NotFound} />
			</Routes>
		</div>
	);
};

export default Main;
